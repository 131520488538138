import React from 'react'

import './Map.scss'

export const Map = () => (
  <div className="map-responsive mt-5 mb-5">
    <iframe
      title="CovHack Map"
      src=""
      scrolling="no"
      frameBorder="0"
    />
  </div>
)
